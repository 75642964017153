import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as scrollToTop from './scripts/scroll-to-top';
scrollToTop.init();

import * as nav from './scripts/nav';
nav.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();